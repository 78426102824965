
import React, { useState, useEffect } from "react";
import {
    auth, signInAnonymously, child, Init,
    get, ref, getDatabase,
} from './components/firebase'
import header from './images/Header.png'
import TextMatter from './images/Text_Matter.png'
import Button from './images/Button.png'
import Bottom from './images/Bottom.png'
import RealityReach from './images/RealityReach.png'
import styles from './styles/Home.module.css'

import { useNavigate } from 'react-router-dom'

export default function Home() {

    const [userDetails, setUserDetails] = useState(null)
    var init = Init()
    const db = getDatabase()
    const navigate = useNavigate()
    const [prompt, setPrompt] = useState({ type: "default", message: "huh" })
    const [user, setUser] = useState(null)
    useEffect(() => {
        //VerifyProfileKey()
    }, []);


    function VerifyProfileKey() {
        var dbRef = ref(db)

        get(child(dbRef, "deployed/users/ProfileKeys/" + StringSplitter() + "/1")).then((snapshot) => {
            if (snapshot.exists()) {
           
                if (snapshot.val().SubscriptionState == "Active") {
                    LoginAnonymously(snapshot.val().UserName)
                }
                else {
                    //setPrompt({ type: "info", message: "Profile has been deactivated. Please contact owner for details." })
                    alert("Profile has been deactivated. Please contact owner for details.")
                }

                //setUserDetails(snapshot.val())
                //console.log(snapshot.val().UserName)
            }
            else {
                alert("You are not authorized to access this experience" )
            }
        })
    }

    function LoginAnonymously(UserName) {
        signInAnonymously(auth)
            .then(() => {
                setUser(auth.currentUser.uid)
                Redirect(UserName, StringSplitter())
                /*  alert(auth.currentUser.uid)  */
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
            });
    }

    function Redirect(value, key) {
        navigate(value + "/?s=" + key)
    }



    function StringSplitter() {
        //http://localhost:3001/?s=dada
        var keyArray = window.location.search.split('=')
        //var keyArray2 = window.location.search
        var key = keyArray[1]
        return key
    }

    function Click() {
        /*   if(user){
              Redirect(userDetails.UserName,StringSplitter())
          }
          else{
              alert('You are not authorized to view the experience')
          } */
        VerifyProfileKey()
    }



    return (<>
     
            <head>
                <title>The AR Visiting Card</title>
                <meta name="description" content="Landing page for the AR Visiting Card." />
                <link rel="icon" href="https://reality-reach-assets.storage.googleapis.com/ar-card/images/RealityReach_black.png" />
                {/*  <link rel="icon" href="/favicon.ico" /> */}
            </head>
            <div className={styles.container}>
                <main className={styles.main} style={{ minHeight: "100vh" }} >

                    <h1 className={styles.title}>
                        {/*  Welcome to <a href="https://nextjs.org">Next.js!</a> */}
                        <img src={header} height="60" width="300" />
                    </h1>

                    <img src={TextMatter} height="171.42" width="300" />

                    <img src={Button}
                        height="51.485"
                        width="200"
                        //onClick={() => { RetrieveKey(Click()) }} 
                        onClick={() => { Click() }}
                        variant="contained" />

                    <p className={styles.description}>
                        <img src={Bottom} height="13" width="300" />
                    </p>

                </main>

                <footer className={styles.footer}>
                    <a
                        href="https://realityreach.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className={styles.logo}>
                            <img sx={{
                                margin: '10px'
                            }}
                                src={RealityReach}
                                alt="Reality Reach Logo" width={150} height={63.15}
                            />
                        </span>
                    </a>
                </footer>


            </div>
       

    </>)
}