import PageOne from './ARpages/pageOne'

import {useState} from 'react'


export default function ExperienceManager({uid,username}){

 return(

     <PageOne {...{uid,username}} />
 )
}