
import { initializeApp } from "firebase/app";
/* import { getAnalytics } from "firebase/analytics"; */
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

import {
  getDatabase, ref as dbRef, get as dbget,
  child as dbChild

} from "firebase/database"

import {
  getAuth, onAuthStateChanged as authChange,
  signInAnonymously as signInAnon
} from "firebase/auth"

import {
  getStorage,
  ref as strRef,
  uploadBytesResumable,
  getDownloadURL
}
  from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCIb9RO9-d5Ercyx6LduKwzDVc2sACQfYk",
  authDomain: "reality-reach.firebaseapp.com",
  databaseURL: "https://reality-reach-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reality-reach",
  storageBucket: "reality-reach.appspot.com",
  messagingSenderId: "934943777462",
  appId: "1:934943777462:web:abbb7c28afcbf55436fc17",
  measurementId: "G-7J6NWTT7JM"
};

export const Init = () => {
  var app = initializeApp(firebaseConfig)
  //eslint-disable-next-line
  //self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Lfp6eghAAAAAP3JpJ2dNkNfZtNerG_eQkPwS0h9'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const onAuthStateChanged = authChange
export const signInAnonymously = signInAnon

export const get = dbget
export const ref = dbRef
export const child = dbChild


export { getDatabase,getStorage,getDownloadURL ,strRef}