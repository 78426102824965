
import React, { useState, useEffect, useRef } from "react";

import { useParams } from 'react-router-dom';
import axios from 'axios'


import ExperienceManager from "./components/experienceManager";

import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';
import './App.css'

import './styles/styles.css'
/* import mindarViewer from "./mindar-viewer"; */

import {
    auth, get, child, ref, getDatabase,
    getStorage, getDownloadURL, strRef, Init
} from './components/firebase'

export default function UserName() {

    const sceneRef = useRef(null)
    const [uid, setUid] = useState(null)
    const [profileLive, setProfileLive] = useState(false)
    const [markerUrl, setMarkerUrl] = useState("")
    const [marker, setMarker] = useState("")
    const init = Init()
    var CustomMarkerExists = false
    const db = getDatabase()
    const dbRef = ref(db)

    const { username } = useParams();

    /* useEffect(() => {
        if (markerUrl != "") {
             console.log("emtered")
            const sceneEl = sceneRef.current;
            const arSystem = sceneEl.systems["mindar-image-system"];
            sceneEl.addEventListener('renderstart', () => {
                arSystem.start(); // start AR 
            });
            
            //return () => {
             //   arSystem.stop();
            //}
        }
    }, [markerUrl]) */

    const [tabHasFocus, setTabHasFocus] = useState(true);

    useEffect(() => {
        if (markerUrl != "") {
            const sceneEl = sceneRef.current;
            const arSystem = sceneEl.systems["mindar-image-system"];

            const handleFocus = () => {
                console.log('Tab has focus');
                arSystem.start()
                setTabHasFocus(true);
            };

            const handleBlur = () => {
                console.log('Tab lost focus');

                arSystem.stop();
                setTabHasFocus(false)
            };

            window.addEventListener('focus', handleFocus);
            window.addEventListener('blur', handleBlur);

            sceneEl.addEventListener('renderstart', () => {
                arSystem.start(); // start AR 

            });
            /* return () => {
                window.removeEventListener('focus', handleFocus);
                window.removeEventListener('blur', handleBlur);
                arSystem.stop();
            } */
        }

    }, [markerUrl]);


    useEffect(() => {
        getDec(StringSplitter(), "Profile")
            .then(async (result) => {
                await CheckCustomMarker(result);
                await CheckProfileKeyAndUsername(result);
                console.log(markerUrl)
            })
        /*  alert("user is there") */
    }, [])

    async function getDec(query, type) {
        const optionss = await axios.post('https://reality-reach.el.r.appspot.com/api/decryption', {
            query: query,
            type: type,
        })
        console.log(optionss.data.data)
        return optionss.data.data
    }

    function StringSplitter() {
        //http://localhost:3001/?s=dada
        var keyArray = window.location.search.split('=')
        //var keyArray2 = window.location.search
        var key = keyArray[1]
        return key
    }

    async function CheckCustomMarker(result) {
        setUid(result)
        const storage = getStorage()
        await getDownloadURL(strRef(storage, 'users/' + result + "/1/CheckoutDetails/Marker.mind"))
            .then((url) => {
                setMarkerUrl("imageTargetSrc:" + url + "; autoStart: false; uiError: no; uiScanning: #example-scanning-overlay;")
                CustomMarkerExists = true
                console.log("Marker Exists")
            })
            .catch((error) => {
                // Handle any errors
            });
    }

    async function CheckProfileKeyAndUsername(result) {
        await get(child(dbRef, "deployed/users/Details/" + username + "/" + result)).then((snapshot) => {
            if (snapshot.exists()) {
                setUid(result)
                //alert(snapshot.val().Marker)
                setMarker(snapshot.val().Marker)
                if (!CustomMarkerExists) {
                    if (snapshot.val().Marker == "Pixel") {
                        alert("AR Profile is under deployment. The image marker might not be stable. Please use with caution.")
                        setMarkerUrl("imageTargetSrc:https://reality-reach-assets.storage.googleapis.com/ar-card/markers/" + snapshot.val().Marker + ".mind; autoStart: false; uiError: no; uiScanning: #example-scanning-overlay;")
                    }
                    else if (snapshot.val().Marker == "Tesseract") {
                        alert("AR Profile is under deployment and would be available soon. Thank you for your patience.")
                    }
                    else {
                        setMarkerUrl("imageTargetSrc:https://reality-reach-assets.storage.googleapis.com/ar-card/markers/" + snapshot.val().Marker + ".mind; autoStart: false; uiError: no; uiScanning: #example-scanning-overlay;")
                    }

                }
            }//https://reality-reach-assets.storage.googleapis.com/ar-card/images/icons/Button_Call.png
            else {
                alert("You are not authorized to view this experience")
            }
        })
    }



    return (<>
        <head>
            <title>{username}'s AR Profile</title>
            <link rel="icon" href="https://reality-reach-assets.storage.googleapis.com/ar-card/images/RealityReach_black.png" />
        </head>
        <div className="App">
            <div id="example-scanning-overlay"
                className="hidden">
                <div className="inner">
                    <img src={"https://reality-reach-assets.storage.googleapis.com/ar-card/markers/" + marker + ".png"} />
                    <div className="scanline"></div>
                </div>
            </div>
            <div className='container'>
                {markerUrl == "" ? (<></>) :
                    (<>

                        <a-scene ref={sceneRef}
                            mindar-image={markerUrl}
                            color-space="sRGB"
                            embedded renderer="colorManagement: true, physicallyCorrectLights,
                     maxCanvasWidth: 3000; maxCanvasHeight: 3000;"
                            vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">


                            {tabHasFocus ? (
                                <a-camera position="0 0 0" look-controls="enabled: false"
                                    cursor="fuse: false; rayOrigin: mouse;"
                                    raycaster="far: 10000; objects: .clickable"></a-camera>
                            ) :
                                (<></>)
                            }

                            <a-entity mindar-image-target="targetIndex: 0">

                                <ExperienceManager {...{ uid: uid, username: username }} />

                            </a-entity>
                        </a-scene>

                    </>)}

            </div>
        </div>
    </>
    )
}
