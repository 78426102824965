
import { useEffect, useState } from 'react'

import {
  getDatabase, ref, get, child,
  getStorage, getDownloadURL, strRef, Init
} from '../firebase'
import headerTile from '../../images/Tiles/headerTile.png'
import dynamicTile from '../../images/Tiles/dynamicTile.png'
import page2accessTile from '../../images/Tiles/page2accessTile.png'
import iconTray from '../../images/Tiles/iconTray.png'
import axios from 'axios'

import { ResolveImage } from '../Images/ResolveImage'

export default function PageOne({ uid, username }) {

  const [fetched, setFetched] = useState(false)





  const [pageOneImagesState, setPageOneImagesState] =
    useState({
      HeaderTile: headerTile,
      DynamicTile: dynamicTile,
      Page2AccessTile: page2accessTile,
      IconTray: iconTray
    })

  const [iconTrayDetails, setIconTrayDetails] =
    useState({
      Format: "",
      One: "Undefined",
      Two: "Undefined",
      Three: "Undefined",
      Four: "Undefined",
      Five: "Undefined",
      Six: "Undefined",
      Call: "",
      Email: "",
      Website: "",
      LinkedIn: "",
      WhatsApp: "",
      Location: "",
      Instagram: ""
    })

  useEffect(() => {
    const init = Init()
    const db = getDatabase()

    if (!fetched && uid) {
      FetchUserImages(uid)
      FetchIconTrayDetails()
      setFetched(true)
    }

  }, [fetched,uid])


  async function getDec(query, type) {
    const optionss = await axios.post('https://reality-reach.el.r.appspot.com/api/decryption', {
      query: query,
      type: type,
    })
    /* console.log(optionss.data.data) */
    return optionss.data.data
  }


  async function FetchUserImages(uid) {
    const db = getDatabase()
    var dbRef = ref(db)

    const storage = getStorage()

    var imageArray = []
    await getStorageAndSetProperty(uid, imageArray, "HeaderTile")
    await getStorageAndSetProperty(uid, imageArray, "DynamicTile")
    await getStorageAndSetProperty(uid, imageArray, "Page2AccessTile")
    await getStorageAndSetProperty(uid, imageArray, "IconTray")

    console.log(imageArray)

    /* let blob = await fetch(imageArray[0]).then(r => r.blob())
      .then(res => console.log(res)); */
    /*  console.log(blob) */



    setPageOneImagesState({
      ...pageOneImagesState,
      HeaderTile: imageArray[0],
      DynamicTile: imageArray[1],
      Page2AccessTile: imageArray[2],
      IconTray: imageArray[3]
    })

  }

  async function getStorageAndSetProperty(uid, imageArray, title) {
    const db = getDatabase()
    var dbRef = ref(db)
    const storage = getStorage()
    await getDownloadURL(strRef(storage, 'users/' + uid + "/1/UserDetails/Images/" + title))
      .then((url) => {
        imageArray.push(url)

      })
      .catch((error) => {
        // Handle any errors
        /*  alert(error) */
        imageArray.push(null)
      });
  }




  function FetchImages() {
    /* alert('fetched') */
    const db = getDatabase()
    var dbRef = ref(db)
    get(child(dbRef, "deployed/users/Details/" + username + "/" + uid + "/Images")).then((snapshot) => {
      if (snapshot.exists()) {
        setPageOneImagesState({
          ...pageOneImagesState,
          HeaderTile: snapshot.val().HeaderTile,
          DynamicTile: snapshot.val().DynamicTile,
          Page2AccessTile: snapshot.val().Page2AccessTile,
          IconTray: snapshot.val().IconTray
        })
        //alert(snapshot.val().HeaderTile)
      }
      else { alert('Fetch error, please refresh.') }
    })
  }

  function FetchIconTrayDetails() {
    const db = getDatabase()
    var dbRef = ref(db)
    get(child(dbRef, "deployed/users/Details/" + username + "/" + uid + "/IconTray")).then((snapshot) => {
      if (snapshot.exists()) {
        setIconTrayDetails({
          ...iconTrayDetails, Format: snapshot.val().Format,
          One: snapshot.val().One,
          Two: snapshot.val().Two,
          Three: snapshot.val().Three,
          Four: snapshot.val().Four,
          Five: snapshot.val().Five,
          Six: snapshot.val().Six,
          Call: snapshot.val().Call,
          Email: snapshot.val().Email,
          Website: snapshot.val().Website,
          LinkedIn: snapshot.val().LinkedIn,
          WhatsApp: snapshot.val().WhatsApp,
          Location: snapshot.val().Location,
          Instagram: snapshot.val().Instagram
        })


      }
    })
  }

  function InvokePositionFuntion(position) {
    switch (position) {
      case "One": InvokeIconFunction(iconTrayDetails.One); break;
      case "Two": InvokeIconFunction(iconTrayDetails.Two); break;
      case "Three": InvokeIconFunction(iconTrayDetails.Three); break;
      case "Four": InvokeIconFunction(iconTrayDetails.Four); break;
      case "Five": InvokeIconFunction(iconTrayDetails.Five); break;
      case "Six": InvokeIconFunction(iconTrayDetails.Six); break;
    }
  }

  function InvokeIconFunction(icon) {
    switch (icon) {
      case "Call": Call(iconTrayDetails.Call); break;
      case "Email": Email(iconTrayDetails.Email); break;
      case "Website": Website(iconTrayDetails.Website); break;
      case "LinkedIn": LinkedIn(iconTrayDetails.LinkedIn); break;
      case "WhatsApp": WhatsApp(iconTrayDetails.WhatsApp); break;
      case "Location":
      case "Instagram": Instagram(iconTrayDetails.Instagram); break;
    }
  }

  function Call(value) {
    getDec(value, "Call")
      .then((result) => {
        window.open("tel:" + result, "_self",)
      })
  }

  function Email(value) {

    getDec(value, "Email")
      .then((result) => {
        window.open(
          "mailto:" + result,
          "_self",
          "popUpWindow",
          "height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no"
        )
      })
  }

  function WhatsApp(value) {

    getDec(value, "WhatsApp")
      .then((result) => {
        window.open("https://api.whatsapp.com/send?phone=" + result, "_self",)
      })
  }

  function Instagram(value) {
    getDec(value, "Instagram")
      .then((result) => {
        window.open("https://www.instagram.com/" + result, "_self")
      })
  }

  function Website(value) {
    getDec(value, "Website")
      .then((result) => {
        window.open("https://" + result, "_self",)
      })
  }

  function LinkedIn(value) {
    getDec(value, "LinkedIn")
      .then((result) => {
        window.open(result, "_self")
      })
  }



  return (
    <>

      <a-plane
        src={pageOneImagesState.HeaderTile}
        width="1.05"
        class="pageOne ui"
        shader="flat"
        height="0.602132"
        opacity="0.99"
        position="0 1.25 0"
      ></a-plane>

      <a-plane
        src={pageOneImagesState.DynamicTile}
        width="0.5"
        id="page2accessTile"
        class="clickable button pageOne ui"
        shader="flat"
        height="0.5"
        opacity="0.99"
        scale="1 1 1"
        position="-0.265 0.6 0"
      ></a-plane>

      <a-plane
        src={pageOneImagesState.Page2AccessTile}
        width="0.5"
        id="page2accessTile"
        class="clickable button pageOne ui"
        shader="flat"
        height="0.5"
        opacity="0.99"
        scale="1 1 1"
        position="0.265 0.6 0"
      ></a-plane>

      {/* Row One */}
      <a-plane
        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        scale="1 1 1"
        onClick={() => { InvokePositionFuntion("One") }}
        position="-0.3 -0.5 -0.1"
      ></a-plane>

      <a-plane
        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        onClick={() => { InvokePositionFuntion("Two") }}
        scale="1 1 1"
        position="0 -0.5 -0.1"
      ></a-plane>

      <a-plane
        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        onClick={() => { InvokePositionFuntion("Three") }}
        scale="1 1 1"
        position="0.3 -0.5 -0.1"
      ></a-plane>

      {/* Row Two */}
      <a-plane

        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        scale="1 1 1"
        onClick={() => { InvokePositionFuntion("Four") }}
        position="-0.3 -0.8 -0.1"
      ></a-plane>

      <a-plane

        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        scale="1 1 1"
        onClick={() => { InvokePositionFuntion("Five") }}
        position="0 -0.8 -0.1"
      ></a-plane>

      <a-plane

        class="clickable button pageOne ui hitbox"
        width="0.25"
        height="0.25"
        opacity="0"
        onClick={() => { InvokePositionFuntion("Six") }}
        scale="1 1 1"
        position="0.3 -0.8 -0.1"
      ></a-plane>

      {/*   <a-plane
      id="ARmetaWebsiteRedirect"
      class="clickable button pageOne ui hitbox"
      width="0.4"
      height="0.15"
      opacity="0.99"
      scale="1 1 1"
      position="-0.3 -1.07 0"
    ></a-plane> */}

      <a-plane
        src={pageOneImagesState.IconTray}
        width="1.05"

        class="pageOne ui"
        shader="flat"
        height="0.6"
        opacity="0.99"
        scale="1 1 1"
        position="0 -0.65 0"
      ></a-plane>
    </>
  )
}