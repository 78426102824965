import React, { useState } from 'react';
import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';
import './App.css';
import Home from './Home';

import { Routes, Route, Link } from "react-router-dom";

import MindARViewer from './mindar-viewer';

import UserName from './UserName';

function App() {
  return (
    <div className="App">

      {/*  <div className="container">
        <MindARViewer />
        <video></video>
      </div>  */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:username" element={<UserName />} /> 
      </Routes>


    </div>
  );
}

export default App;
